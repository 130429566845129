import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Password } from 'primereact/password';
import { ErrorValidationMessage } from '../../components/ErrorValidationMessage';
import { Toast } from 'primereact/toast';

import { useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

import { Logo } from '../../components/Logo';

import '../../layout/sass/_login.scss'

function LoginPage() {


    const myToast = useRef(null);

    const showToast = (severityValue, summaryValue, detailValue) => {
        myToast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
    }

    const { signIn, loadingAuth } = useContext(AuthContext);

    const schema = Yup.object().shape({
        username: Yup
            .string()
            .email("O e-mail inserido é inválido")
            .required("O e-mail é obrigatório"),
        password: Yup
            .string()
            .required("A senha é obrigatória")
    })

    const formik = useFormik({
        initialValues: {
            client_id: 'Prototipo_front',
            username: '',
            password: '',
            grant_type: 'password'
        },

        validationSchema: schema,

        onSubmit: (data, { setSubmitting }) => {

            if (data.username !== '' && data.password !== '') {
                const success = signIn(data.username, data.password)
                success.then(function (result) {
                    if (!result) {
                        setSubmitting(false)
                        showToast('error', 'Login', 'Email ou senha inválido')
                    }
                })

            }
        }
    })


    return (
        <Dialog
            visible={true}
            maximized={true}
            closable={false}
            showHeader={false}
            onHide={() => null}
        >
            <Toast ref={myToast} />

            <div id="login-container" className="p-d-flex p-jc-center p-ai-center p-flex-column">
                <Logo />
                <form className="p-fluid p-grid p-col-12 p-sm-8 p-md-6 p-lg-5 p-xl-4 p-justify-center p-mx-0 p-px-0 p-mt-2" onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-col-12 p-mt-5 p-mb-4 p-justify-center p-mx-0 p-px-0">
                        <div className="p-field p-col-12">
                            <label htmlFor="username">E-mail</label>
                            <InputText
                                id="username"
                                name="username"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.username && formik.touched.username &&
                                <ErrorValidationMessage message={formik.errors.username} />
                            }
                        </div>
                        <div className="p-field p-col-12">
                            <label htmlFor="password">Senha</label>
                            <Password
                                id="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                feedback={false}
                                toggleMask
                            />
                            {formik.errors.password && formik.touched.password &&
                                <ErrorValidationMessage message={formik.errors.password} />
                            }
                            <div className="p-grid p-col-12 p-justify-end p-px-0 p-m-0">
                                <Link className="recuperar-senha" to="/reset">Esqueci minha senha</Link>
                            </div>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-center p-ai-center p-p-2">
                        <Button id="login-btn" type="submit" label='Entrar' loading={loadingAuth} />
                    </div>
                </form>

                <div className="p-mb-2 p-mt-5">
                    <span className="text">Não possui conta?</span> <Link to="/register">Criar conta</Link>
                </div>
            </div>
        </Dialog>
    );
}


export default LoginPage;
import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaItens } from '../../components/TabelaItens';

export default function ItensPage() {

    return (
        <>           
            <MenuMain >
                <TabelaItens />
            </MenuMain>
            <AppFooter />    
        </>
    );
}

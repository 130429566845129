import React from 'react';
import MenuMain from '../../MenuMain';
import { FormInstalacao } from '../../components/FormInstalacao';
import { AppFooter } from '../../AppFooter';


export default function CadastrarInstalacaoPage() {

  const defaultValues = {
    'endereco': '',
    'responsavel': '',
    'empenho': '',
    'numeroInstalacao': '',
    'dataVigencia': '',
    'medidor': '',
    'descricao': '',
    'observacao': '',
    'itens': '',
  }

  return (
    <div>
      <MenuMain>
         <FormInstalacao
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}


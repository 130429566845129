import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaLiquidacao } from '../../components/TabelaLiquidacao';

export default function LiquidacaoPage() {

    return (
        <>           
            <MenuMain >
                <TabelaLiquidacao />
            </MenuMain>
            <AppFooter />    
        </>
    );
}

import React, { useEffect, useState } from 'react';

import { FormItens } from '../../components/FormItens';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarItensPage(props) {

    const [itens, setItens] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingItens, setErrorGettingItens] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idItens] = useState(props.match.params.id)

    const getItens = async (id) => {
        setIsLoading(true);

        try {
            const response = await api.get(`itens/${id}`)
            
            setItens({
                'id': response.data.id,
                'codigo': response.data.codigo,
                'descricaoEmpenho': response.data.descricaoEmpenho,  
                'descricaoFatura': response.data.descricaoFatura,     
                'unidade': response.data.unidade,         
            })

            setErrorGettingItens(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingItens(true)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getItens(idItens)
    }, [])

    return (
        <>
            <MenuMain >
                {
                    errorGettingItens?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getItens(idItens)}
                            isLoading={isLoading}
                        />
                        :
                        <FormItens
                            defaultValues={itens}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}

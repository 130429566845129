import React, { useCallback, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import { BrCalendar } from './BrCalendar';
import { InputNumber } from 'primereact/inputnumber';
import { FormEmpenhoItens } from './FormEmpenhoItens';
import { TabelaEmpenhoItens } from './TabelaEmpenhoItens';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import api from '../api/api';
import '../layout/sass/_form.scss';

export const FormEmpenho = ({ isEditing, isLoading, defaultValues }) => {
    const { id } = useParams();
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [listSecretarias, setListSecretarias] = useState(false);
    const [listFornecedores, setListFornecedores] = useState(false);
    const [empenhoItens, setEmpenhoItens] = useState([]);

    const schema = Yup.object().shape({
        secretariaId: Yup
            .number()
            .required("A secretaria é obrigatória"),
        fornecedorId: Yup
            .number()
            .required("O fornecedor é obrigatório"),
        ficha: Yup
            .number()
            .required("A ficha é obrigatória"),
        fonte: Yup
            .string()
            .required("A fonte é obrigatória"),
        numeroEmpenho: Yup
            .number()
            .required("O empenho é obrigatório"),
        dataEmissao: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de emissão é obrigatória"),
        dataVigencia: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de vigência é obrigatória"),
        valorTotal: Yup
            .number()
            .required("O valor total é obrigatória"),

    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)
            data.empenhoItens = empenhoItens
            try {
                if (!isEditing) {

                    await api.post("empenho", data)
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Empenho adicionada com sucesso` });
                    setTimeout(() => {
                        history.push('/empenho')

                    }, 2000)

                }
                else {
                    await api.put("empenho", data)

                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Empenho atualizada com sucesso` });
                    setTimeout(() => {

                        history.push('/empenho')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar o empenho!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar o empenho!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const handleGetSecretarias = useCallback(async () => {

        await api.get("secretaria/secretariaAll").then((response) => {
            const secretarias = response.data.map(item => {
                return {
                    id: item.id,
                    nome: item.nome
                }
            });

            setListSecretarias(secretarias);
        });

    }, [])

    const handleGetFornecedores = useCallback(async () => {

        await api.get("fornecedor/fornecedorAll").then((response) => {
            const fornecedors = response.data.map(item => {
                return {
                    id: item.id,
                    nome: item.nome
                }
            });

            setListFornecedores(fornecedors);
        });

    }, [])

    useEffect(async () => {
        await handleGetSecretarias();
        await handleGetFornecedores();

    }, [handleGetSecretarias,handleGetFornecedores]);


    useEffect(async () => {

        if (isEditing) {
            const response = await api.get(`empenhoItens/empenhoId/${id}`);

            response.data.map(empenhoItem => {
                setEmpenhoItens(empenhoItens => [...empenhoItens, empenhoItem])
            })

        }

    }, []);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Empenho</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="secretariaId"
                                className={classNames({ 'p-error': isFormFieldValid('secretariaId') })}
                            >
                                Secretarias
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="secretariaId"
                                        name="secretariaId"
                                        options={listSecretarias}
                                        filter={true}
                                        emptyMessage={"Nenhuma secretaria encontrada"}
                                        optionLabel="nome"
                                        optionValue="id"
                                        placeholder="Selecione uma secretaria"
                                        className={classNames({ 'p-invalid': isFormFieldValid('secretariaId') })}
                                        value={formik.values.secretariaId}
                                        onChange={option => formik.setFieldValue("secretariaId", option.value)}
                                        emptyFilterMessage={"Nenhuma secretaria encontrada"}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.secretariaId && formik.touched.secretariaId &&
                                <ErrorValidationMessage message={formik.errors.secretariaId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="ficha"
                                className={classNames({ 'p-error': isFormFieldValid('ficha') })}
                            >
                                Ficha
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="ficha"
                                        name="ficha"
                                        type="number"
                                        className={classNames({ 'p-invalid': isFormFieldValid('ficha') })}
                                        value={formik.values.ficha}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.ficha && formik.touched.ficha &&
                                <ErrorValidationMessage message={formik.errors.ficha} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="fonte"
                                className={classNames({ 'p-error': isFormFieldValid('fonte') })}
                            >
                                Fonte
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="fonte"
                                        name="fonte"
                                        className={classNames({ 'p-invalid': isFormFieldValid('fonte') })}
                                        value={formik.values.fonte}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.fonte && formik.touched.fonte &&
                                <ErrorValidationMessage message={formik.errors.fonte} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="numeroEmpenho"
                                className={classNames({ 'p-error': isFormFieldValid('numeroEmpenho') })}
                            >
                                Empenho
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="numeroEmpenho"
                                        name="numeroEmpenho"
                                        type="number"
                                        className={classNames({ 'p-invalid': isFormFieldValid('numeroEmpenho') })}
                                        value={formik.values.numeroEmpenho}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.numeroEmpenho && formik.touched.numeroEmpenho &&
                                <ErrorValidationMessage message={formik.errors.numeroEmpenho} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-2 p-md-2">
                                <label
                                    htmlFor="dataEmissao"
                                    className={classNames({ 'p-error': isFormFieldValid('dataEmissao') })}
                                >
                                    Data de Emissão
                                </label>
                                <BrCalendar
                                    id="dataEmissao"
                                    name="dataEmissao"
                                    value={formik.values.dataEmissao}
                                    onChange={formik.handleChange}
                                    placeholder={ formik.values.dataEmissao != null && isEditing ? new Date(formik.values.dataEmissao).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataEmissao && formik.touched.dataEmissao &&
                                    <ErrorValidationMessage message={formik.errors.dataEmissao} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-2 p-md-2">
                                <label
                                    htmlFor="dataVigencia"
                                    className={classNames({ 'p-error': isFormFieldValid('dataVigencia') })}
                                >
                                    data de Vigência
                                </label>
                                <BrCalendar 
                                    id="dataVigencia"
                                    name="dataVigencia"
                                    value={formik.values.dataVigencia}
                                    onChange={formik.handleChange}
                                    placeholder={ formik.values.dataVigencia != null && isEditing ? new Date(formik.values.dataVigencia).toLocaleDateString('pt-BR') : ""}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dataVigencia') })}
                                    yearRange={`2000:${new Date().getFullYear()}`}
                                />
                                {formik.errors.dataVigencia && formik.touched.dataVigencia &&
                                    <ErrorValidationMessage message={formik.errors.dataVigencia} />
                                }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="fornecedorId"
                                className={classNames({ 'p-error': isFormFieldValid('fornecedorId') })}
                            >
                                Fornecedores
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="fornecedorId"
                                        name="fornecedorId"
                                        options={listFornecedores}
                                        filter={true}
                                        emptyMessage={"Nenhum fornecedor encontrada"}
                                        optionLabel="nome"
                                        optionValue="id"
                                        placeholder="Selecione um fornecedor"
                                        className={classNames({ 'p-invalid': isFormFieldValid('fornecedorId') })}
                                        value={formik.values.fornecedorId}
                                        onChange={option => formik.setFieldValue("fornecedorId", option.value)}
                                        emptyFilterMessage={"Nenhum fornecedor encontrada"}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.fornecedorId && formik.touched.fornecedorId &&
                                <ErrorValidationMessage message={formik.errors.fornecedorId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="valorTotal"
                                className={classNames({ 'p-error': isFormFieldValid('valorTotal') })}
                            >
                                Valor Total
                            </label>
                            {
                                !isLoading ?

                                    <InputNumber 
                                        id="valorTotal"
                                        name="valorTotal"
                                        mode="currency" minFractionDigits={2} 
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorTotal') })}
                                        locale="pt-BR"
                                        value={formik.values.valorTotal}
                                        onChange={(e) => formik.setFieldValue("valorTotal", e.value)}  
                                    />                                    
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.valorTotal && formik.touched.valorTotal &&
                                <ErrorValidationMessage message={formik.errors.valorTotal} />
                            }
                        </div>


                        <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="valorLiquidado"
                                className={classNames({ 'p-error': isFormFieldValid('valorLiquidado') })}
                            >
                                Valor Liquidado
                            </label>
                            {
                                !isLoading ?
                                    <InputNumber 
                                        id="valorLiquidado"
                                        name="valorLiquidado"
                                        mode="currency" minFractionDigits={2} 
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorLiquidado') })}
                                        locale="pt-BR"
                                        value={formik.values.valorLiquidado}
                                        onChange={(e) => formik.setFieldValue("valorLiquidado", e.value)}  
                                    /> 
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.valorLiquidado && formik.touched.valorLiquidado &&
                                <ErrorValidationMessage message={formik.errors.valorLiquidado} />
                            }
                        </div>
                        
                    </div>



                    <FormEmpenhoItens
                        isEditing={isEditing}
                        isLoading={isLoading}
                        setEmpenhoItens={setEmpenhoItens}
                    />

                    <br />

                    <div className="p-d-flex p-jc-end p-mr-1">
                        <TabelaEmpenhoItens
                            isEditing={isEditing}
                            atualizarEmpenhoItens={setEmpenhoItens}
                        />
                    </div>



                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />
                        }
                    </div>
                </form>
            </div>
        </div>

    );
}

import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';

export const FormFornecedor = ({ isEditing, isLoading, defaultValues }) => {
    
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)

    // const [nomeError, setNomeError] = useState('');

    const schema = Yup.object().shape({
        nome: Yup
            .string()
            .required("O nome é obrigatório"),
        codigo: Yup
            .number()
            .required("O código é obrigatório")

    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        

            try {
                if (!isEditing) {

                    await api.post("fornecedor", data)
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/fornecedor')

                    }, 2000)

                }
                else {

                    await api.put("fornecedor", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/fornecedor')

                    }, 2000)
                }
            }
            catch (error) {

                // if(error.response.data){
                //     error.response.data.nome ? setNomeError(error.response.data.nome) : setNomeError('')
                // }

                if (!isEditing)
                    // console.log(error.response.data.nome)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar fornecedor!', detail: error.toString()});
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar fornecedor!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Fornecedor</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="nome"
                                className={classNames({ 'p-error': isFormFieldValid('nome') })}
                            >
                                Nome
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="nome"
                                        name="nome"
                                        className={classNames({ 'p-invalid': isFormFieldValid('nome') })}
                                        value={formik.values.nome}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.nome && formik.touched.nome &&
                                <ErrorValidationMessage message={formik.errors.nome} />
                            }
                            {/* {
                                nomeError ? <span style={{ color: 'red', fontSize: '12px'}}>{nomeError}</span> : ''
                            } */}
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="codigo"
                                className={classNames({ 'p-error': isFormFieldValid('codigo') })}
                            >
                                Código
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        type='number'
                                        className={classNames({ 'p-invalid': isFormFieldValid('codigo') })}
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.codigo && formik.touched.codigo &&
                                <ErrorValidationMessage message={formik.errors.codigo} />
                            }
                        </div>

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />
                        }
                    </div>
                </form>
            </div>
        </div>

    );
}

import React, { useEffect, useState } from 'react';

import { FormFornecedor } from '../../components/FormFornecedor';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarFornecedorPage(props) {

    const [fornecedor, setFornecedor] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingFornecedor, setErrorGettingFornecedor] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idFornecedor] = useState(props.match.params.id)

    const getFornecedor = async (id) => {
        setIsLoading(true);

        try {
            const response = await api.get(`fornecedor/${id}`)
            
            setFornecedor({
                'id': response.data.id,
                'codigo': response.data.codigo,
                'nome': response.data.nome,          
            })

            setErrorGettingFornecedor(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingFornecedor(true)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getFornecedor(idFornecedor)
    }, [])

    return (
        <>
            <MenuMain >
                {
                    errorGettingFornecedor ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getFornecedor(idFornecedor)}
                            isLoading={isLoading}
                        />
                        :
                        <FormFornecedor
                            defaultValues={fornecedor}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}

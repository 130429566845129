import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaFaturas } from '../../components/TabelaFaturas';

export default function FaturasPage() {

    return (
        <>           
            <MenuMain >
                <TabelaFaturas />
            </MenuMain>
            <AppFooter />    
        </>
    );
}

import React, {} from 'react';

import '../layout/sass/_responsiveTable.scss'
import '../layout/sass/_dashboard.scss';

export const DashBoard = () => {

    return (
        <>
        </>
    );
}

import React, { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { useUserMenu } from '../hooks/useUserMenu';
import '../layout/sass/_tabMenu.scss';
import { useHistory } from 'react-router-dom';

export const UserMenu = () => {

    const {
        // getUserData,
        errorGettingData,
        // errorMessage,
        // isLoading
    } = useUserMenu();
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();

    let items;
    items = [
        { label: 'Admin', icon: 'pi pi-id-card' } ]

    const handleTabChange = (index) => {
        setActiveIndex(index)
        if (index === 0) {
            history.push('/login');
        }
    }
    
    return (
        <div style={{ height: '100vh', backgroundColor: '#fff', overflow: 'auto' }}>
            <TabMenu
                model={items}
                activeIndex={activeIndex}
                onTabChange={(e) => handleTabChange(e.index)}
                className="custom-tab-menu"
            />
            {
            errorGettingData ?
                <></>
                : 
                history.push('/login')
            }
        </div>
    );
}

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';

export const FormInstalacaoEmpenho = ({ isEditing, isLoading, setInstalacaoEmpenho }) => {

    const { id } = useParams();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [listEmpenhos, setListEmpenhos] = useState(false);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const schema = Yup.object().shape({
        peso: Yup
            .number()
            .required("O peso é obrigatório"),
        empenhoId: Yup
            .number()
            .required("O empenho é obrigatório"),

    })

    useEffect(() => {

        if (isEditing) {
            api.get(`instalacao/${id}`).then((response) => { 
                formik.setFieldValue("instalacaoId",response.data.instalacaoId);
            });
        }

    }, []);

    const formik = useFormik({

        initialValues: {
            "peso": 0,
            "empenhoId": null,
        },

        validationSchema: schema,

        enableReinitialize: false,

        onSubmit: async (data) => {
            setIsSubmiting(true)

            setInstalacaoEmpenho(faturaItens => [...faturaItens, data]);
            setIsSubmiting(false)
        }
    })

    const handleGetEmpenhos = useCallback(async () => {

        await api.get("empenho/empenhoAll").then((response) => {
            const empenhos = response.data.map(item => {
                return {
                    id: item.id,
                    numeroEmpenho: item.numeroEmpenho
                }
            });

            setListEmpenhos(empenhos);
        });

    }, [])

    useEffect(async () => {
        await handleGetEmpenhos();

    }, [handleGetEmpenhos]);

    return (

        <Fieldset legend="Empenhos" toggleable  >
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">

                            <div className="p-field p-col-12 p-sm-5">
                                <label
                                    htmlFor="empenhoId"
                                    className={classNames({ 'p-error': isFormFieldValid('empenhoId') })}
                                >
                                    Empenhos
                                </label>
                                {

                                    !isLoading ?
                                        <Dropdown
                                            id="empenhoId"
                                            name="empenhoId"
                                            options={listEmpenhos}
                                            filter={true}
                                            emptyMessage={"Nenhum empenho encontrada"}
                                            optionLabel="numeroEmpenho"
                                            optionValue="id"
                                            placeholder="Selecione um empenho"
                                            className={classNames({ 'p-invalid': isFormFieldValid('empenhoId') })}
                                            value={formik.values.empenhoId}
                                            onChange={option => formik.setFieldValue("empenhoId", option.value)}
                                            emptyFilterMessage={"Nenhum empenho encontrada"}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.empenhoId && formik.touched.empenhoId &&
                                    <ErrorValidationMessage message={formik.errors.empenhoId} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-5">
                                <label
                                    htmlFor="peso"
                                    className={classNames({ 'p-error': isFormFieldValid('peso') })}
                                >
                                    Peso
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="peso"
                                            name="peso"
                                            type="number"
                                            className={classNames({ 'p-invalid': isFormFieldValid('peso') })}
                                            value={formik.values.peso}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.peso && formik.touched.peso &&
                                    <ErrorValidationMessage message={formik.errors.peso} />
                                }
                            </div>

                            

                            <div className="p-d-flex p-jc-end p-mr-1">

                                <Button
                                    icon="pi pi-plus"
                                    type="button"
                                    iconPos="right"
                                    onClick={formik.handleSubmit}
                                    loading={isSubmiting}
                                />
                            </div>

                            <div className="p-formgrid p-grid p-fluid p-mx-0">

                            </div>


                        </div>

                    </form>
                </div>
            </div>


        </Fieldset>

    );
}

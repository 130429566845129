import React from 'react';

import '../layout/sass/_logo.scss';
import logo from '../assets/logo.png';


export const Logo = () => {
    return (
        <img 
            id="logo"
            alt="Logo"
            src={logo}
            width="320" height="250"
        />
    );
}


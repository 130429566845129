import React, { useEffect, useState } from 'react';

import { FormFaturas } from '../../components/FormFaturas';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarFaturasPage(props) {

    const [faturas, setFaturas] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingFaturas, setErrorGettingFaturas] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idFaturas] = useState(props.match.params.id)

    const getFaturas = async (id) => {
        setIsLoading(true);

        try {
            const response = await api.get(`faturas/${id}`)
            
            setFaturas({
                'id': response.data.id,
                'fornecedorId': response.data.fornecedorId,
                'dataEmissao': response.data.dataEmissao,
                'dataLiquidacao': response.data.dataLiquidacao,    
                'dataVencimento': response.data.dataVencimento,      
                'numero': response.data.numero, 
                'valorTotal': response.data.valorTotal, 
                'valorRetencao': response.data.valorRetencao, 
                'faturaInstalacoes': response.data.faturaInstalacoes,                 
            })

            setErrorGettingFaturas(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingFaturas(true)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getFaturas(idFaturas)
    }, [])

    return (
        <>
            <MenuMain >
                {
                    errorGettingFaturas ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getFaturas(idFaturas)}
                            isLoading={isLoading}
                        />
                        :
                        <FormFaturas
                            defaultValues={faturas}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}

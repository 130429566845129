import { Switch } from 'react-router-dom';
import Route from './Route';
import ScrollToTop from '../ScrollToTop';

import SignIn from '../pages/Login';
import SignUp from '../pages/SignUp';
import ResetPassword from '../pages/ResetPassword';
import HomeUserPage from '../pages/home-user';
import HomeAdminPage from '../pages/home-admin';
import LogsPage from '../pages/logs/index';
import EditarLogsPage from '../pages/logs/edit';
import FornecedorPage from '../pages/fornecedor/index';
import CadastrarFornecedorPage from '../pages/fornecedor/create';
import EditarFornecedorPage from '../pages/fornecedor/edit';
import ItensPage from '../pages/itens/index';
import CadastrarItensPage from '../pages/itens/create';
import EditarItensPage from '../pages/itens/edit';
import SecretariaPage from '../pages/secretaria/index';
import CadastrarSecretariaPage from '../pages/secretaria/create';
import EditarSecretariaPage from '../pages/secretaria/edit';
import EmpenhoPage from '../pages/empenho/index';
import CadastrarEmpenhoPage from '../pages/empenho/create';
import EditarEmpenhoPage from '../pages/empenho/edit';
import InstalacaoPage from '../pages/instalacao/index';
import CadastrarInstalacaoPage from '../pages/instalacao/create';
import EditarInstalacaoPage from '../pages/instalacao/edit';
import FaturasPage from '../pages/faturas/index';
import CadastrarFaturasPage from '../pages/faturas/create';
import EditarFaturasPage from '../pages/faturas/edit';
import LiquidacaoPage from '../pages/liquidacao/index';

export default function Routes() {
  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={HomeUserPage} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/admin" component={HomeAdminPage} isPrivate />
        <Route exact path="/logs" component={LogsPage} isPrivate />
        <Route exact path="/logs/edit/:id" component={EditarLogsPage} isPrivate /> 
        <Route exact path="/fornecedor" component={FornecedorPage} isPrivate />
        <Route exact path="/fornecedorcreate" component={CadastrarFornecedorPage} isPrivate />
        <Route exact path="/fornecedor/edit/:id" component={EditarFornecedorPage} isPrivate />
        <Route exact path="/secretaria" component={SecretariaPage} isPrivate />
        <Route exact path="/secretariacreate" component={CadastrarSecretariaPage} isPrivate />
        <Route exact path="/secretaria/edit/:id" component={EditarSecretariaPage} isPrivate />
        <Route exact path="/itens" component={ItensPage} isPrivate />
        <Route exact path="/itenscreate" component={CadastrarItensPage} isPrivate />
        <Route exact path="/itens/edit/:id" component={EditarItensPage} isPrivate />
        <Route exact path="/empenho" component={EmpenhoPage} isPrivate />
        <Route exact path="/empenhocreate" component={CadastrarEmpenhoPage} isPrivate />
        <Route exact path="/empenho/edit/:id" component={EditarEmpenhoPage} isPrivate />
        <Route exact path="/instalacao" component={InstalacaoPage} isPrivate />
        <Route exact path="/instalacaocreate" component={CadastrarInstalacaoPage} isPrivate />
        <Route exact path="/instalacao/edit/:id" component={EditarInstalacaoPage} isPrivate />
        <Route exact path="/faturas" component={FaturasPage} isPrivate />
        <Route exact path="/faturascreate" component={CadastrarFaturasPage} isPrivate />
        <Route exact path="/faturas/edit/:id" component={EditarFaturasPage} isPrivate />
        <Route exact path="/liquidacao" component={LiquidacaoPage} isPrivate />
      </Switch>
    </ScrollToTop>
  )
}
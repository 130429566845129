import React, { useRef, useEffect, useState,useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DialogLogs } from './DialogLogs';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';

export const TabelaLogs = () => {
    const toast = useRef(null);
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null);
    const history = useHistory();
    const [showListaLogs, setShowListaLogs] = useState(false);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {
        }
    });

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Logs</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => setLazyParams({
                            first: 0,
                            rows: 20,
                            sortField: null,
                            sortOrder: null,
                            filters: {
                            },
                            
                        })}
                    />
                </div>
            </div>
        </div>

    );

    const getLogs = useCallback(async (event) => {
        setIsLoading(true)
        setLogs([])

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);          
            }, 250);
        }

        try {

            if (lazyParams.filters === undefined) {

                if (event === undefined) {
                    const response = await api.get(`logs?sort=${sortableCampo},${sortable}`);
                    
                    response.data.content.forEach(log => {
                        setLogs(logs => [...logs, log])
                    })
                } else {
                    const response = await api.get(`logs?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(log => {
                        setLogs(logs => [...logs, log])
                    })

                }

                const respElements = await api.get(`logs?sort=${sortableCampo},${sortable}`);
                    setTotalRecords(respElements.data.totalElements);

            } else {

                const pesquisa = {
                    "id": lazyParams.filters['id'] !== undefined ? lazyParams.filters['id'].value : null,
                    "usuario": lazyParams.filters['usuario'] !== undefined ? lazyParams.filters['usuario'].value : "",
                    "cadastroId": lazyParams.filters['cadastroId'] !== undefined ? lazyParams.filters['cadastroId'].value : null,
                    "cadastro": lazyParams.filters['cadastro'] !== undefined ? lazyParams.filters['cadastro'].value : "",
                    "acao": lazyParams.filters['acao'] !== undefined ? lazyParams.filters['acao'].value : "",
                    
                }

                if (event === undefined) {                    
                    const respElements = await api.post(`logs/searchLogs/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(log => {
                        setLogs(logs => [...logs, log])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }else {                    
                    const respElements = await api.post(`logs/searchLogs/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(log => {
                        setLogs(logs => [...logs, log])
                    })
                    setTotalRecords(respElements.data.totalElements);                    
                }

            }           

        }
        catch (error) {
            if (lazyParams !== undefined) {
                if (event === undefined) {
                    const response = await api.get(`logs?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(log => {
                        setLogs(logs => [...logs, log])
                    })
                } else {
                    const response = await api.get(`logs?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(log => {
                        setLogs(logs => [...logs, log])
                    })

                }

                const respElements = await api.get(`logs?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter logs', detail: error.toString() });
            }
        }

        setIsLoading(false)
    }, [lazyParams,sortable,sortableCampo])

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                await getLogs();                

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchLogs();

    }, [getLogs])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)} />

            </div>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const usuarioBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Usuário</span>
                    <span className="table-data">{rowData.usuario}</span>
                </p>
            </React.Fragment>
        );
    }
    const updatedBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Updated</span>
                    <span className="table-data">{new Date(rowData.updated).toLocaleString("pt-BR")}</span>
                </p>
            </React.Fragment>
        );
    }

    const cadastroIdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Cód Cadastro</span>
                    <span className="table-data">{formatCurrency(rowData.cadastroId != null ? rowData.cadastroId : "Não informado")}</span>                    
                </p>
            </React.Fragment>
        );
    }

    const cadastroBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Cadastro</span>
                    <span className="table-data">{formatCurrency(rowData.cadastro)}</span>                    
                </p>
            </React.Fragment>
        );
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR');
    }

    const acaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Grupo</span>
                    <span className="table-data">{rowData.acao}</span>
                </p>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const handleEditClick = (data) => {

        history.push(`/logs/edit/${data.id}`);

    }

    const handlePrintClick = () => {
        setShowListaLogs(true);
    }

    return (
        <>
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <DialogLogs
                    visible={showListaLogs}
                    setVisible={setShowListaLogs}
                    log={logs}
                />

                <div className="p-my-0 p-dir-rev p-col">
                    <div className="p-my-0">
                        <Button
                            textalign="center"
                            icon="pi pi-print"
                            className="p-button-secondary"
                            style={{ width: '35px' }}
                            onClick={handlePrintClick}
                            tooltip="Gerar relatório"
                            tooltipOptions={{ position: 'top' }}
                        />
                    </div>
                </div>

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">

                        <DataTable
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getLogs}
                            header={header}
                            value={logs}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhum registro encontrado"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} registros`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={lazyParams.filters}
                        >

                            <Column
                                field="id"
                                header="Código"
                                sortable={sortable}
                                body={idBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder='Pesquisar por código'
                            />

                            <Column
                                field="usuario"
                                header="Nome do Usuario"
                                sortable={sortable}
                                body={usuarioBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder='Pesquisar por usuário'
                            />

                            <Column
                                field="updated"
                                header="Updated"
                                sortable={sortable}
                                body={updatedBodyTemplate}
                                
                            />

                            <Column
                                field="cadastroId"
                                header="Cód Cadastro"
                                sortable={sortable}
                                body={cadastroIdBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder='Pesquisar por código cadastrado'
                            />

                            <Column
                                field="cadastro"
                                header="Cadastro"
                                sortable={sortable}
                                body={cadastroBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder='Pesquisar por cadastro'
                            />

                            <Column
                                field="acao"
                                header="Ação"
                                sortable={sortable}
                                body={acaoBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder='Pesquisar por ação'
                            />

                            <Column
                                header="Ações"
                                body={actionBodyTemplate}

                            />

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}
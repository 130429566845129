import React from 'react';
import MenuMain from '../../MenuMain';
import { FormItens } from '../../components/FormItens';
import { AppFooter } from '../../AppFooter';


export default function CadastrarItensPage() {

  const defaultValues = {
    'codigo': '',
    'descricaoEmpenho': '',
    'descricaoFatura': '',
    'unidade': '',
  }

  return (
    <div>
      <MenuMain>
         <FormItens
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}


import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';

export const DialogInstalacaoHistorico = ({ visible, setVisible, instalacaoHistorico, instalacoes }) => {
    const d = new Printd();
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
        
        .header-img {
            display: flex;
            justify-content: center;
        }

        .header {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .header h2 {
            margin-bottom: 0px;
        }

        .header h4 {
            margin-top: 7px;
        }
    `

      

    const listaInstalacaoHistorico = instalacaoHistorico.map((item) => {
        return (
                <tr key={item.id}>
                    <td width="10%" align='center'><span>{item.numero}</span></td> 
                    <td width="10%" align='center'><span>{new Date(item.dataEmissao).toLocaleDateString()}</span></td> 
                    <td width="10%" align='center'><span>{new Date(item.dataVencimento).toLocaleDateString()}</span></td> 
                    <td width="50%" align='center'><span>{item.faturaItens.map(itens => <p className="table-data">{itens.descricaoFatura}</p> )}</span></td> 
                    <td width="5%" align='center'><span>{item.faturaItens.map(itens => <p className="table-data">{itens.unidade}</p> )}</span></td> 
                    <td width="5%" align='center'><span>{item.faturaItens.map(itens => <p className="table-data">{itens.quantidade.toString().replace(".", ",")}</p> )}</span></td> 
                    <td width="10%" align='center'><span>{item.faturaItens.map(itens => <p className="table-data">R$ {itens.valorTotal != undefined ? itens.valorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</p> )}</span></td> 
                </tr>
        )
    })

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '60vw', heigth: '5vw' }}
        >
            {/* {log.length > 0 ? */}
                <div id="print-content">
                    <div className="p-d-flex p-ai-center p-flex-column header">
                    <h2>Instalação: {instalacoes.numeroInstalacao} {instalacoes.dataVigencia != null ? `- Data Vigência: ${new Date(instalacoes.dataVigencia).toLocaleDateString()} ` : ''} </h2>
                    <h3>{instalacoes.responsavel != null ? `Responsavel: ${instalacoes.responsavel} - ` : ''}  
                        {instalacoes.endereco != null ? `Endereço: ${instalacoes.endereco}` : '' }</h3>

                    </div>
                    <table width="100%" border="1" style={{'borderCollapse': 'collapse'}} cellpadding={3} cellspacing={0}>
                        <thead>
                            <tr>
                                <th width="100px" ><i>N° Fatura</i></th>
                                <th width="100px" ><i>Data Emissão</i></th>
                                <th width="100px" ><i>Data Vencimento</i></th> 
                                <th width="100px" ><i>Descrição</i></th>  
                                <th width="100px" ><i>Unidade</i></th>    
                                <th width="100px" ><i>Quantidade</i></th>   
                                <th width="100px" ><i>Valor Total</i></th>   
                                                       
                            </tr>
                        </thead>
                        
                        <tbody>
                            {listaInstalacaoHistorico}
                        </tbody>
                        
                    </table>

                </div>
                {/* :

                null
            } */}


        </Dialog>
    );
}
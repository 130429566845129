import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';

export const DialogLogs = ({ visible, setVisible, log }) => {
    const d = new Printd();
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
        
        .header-img {
            display: flex;
            justify-content: center;
        }

        .header {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .header h2 {
            margin-bottom: 0px;
        }

        .header h4 {
            margin-top: 7px;
        }
    `

    const listaLogs = log.map((item) => {
        return (
                <tr key={item.id}>
                    <td width="230px" align='center'><span>{item.id}</span></td> 
                    <td width="230px" align='center'><span>{item.usuario}</span></td> 
                    <td width="230px" align='center'><span>{new Date(item.updated).toLocaleString("pt-BR")}</span></td> 
                    <td width="230px" align='center'><span>{item.cadastroId}</span></td> 
                    <td width="230px" align='center'><span>{item.cadastro}</span></td> 
                    <td width="230px" align='center'><span>{item.acao}</span></td> 
                </tr>
        )
    })

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    return (
        <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            className='p-scrollpanel-bar-y'
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            style={{ width: '50vw', heigth: '5vw' }}
        >
            {/* {log.length > 0 ? */}
                <div id="print-content">
                    <div className="p-d-flex p-ai-center p-flex-column header">
                        <h2>Logs</h2>

                    </div>
                    <table width="100%" border="1" style={{'borderCollapse': 'collapse'}} cellpadding={3} cellspacing={0}>
                        <thead>
                            <tr>
                                <th width="100px" ><i>Código</i></th>
                                <th width="100px" ><i>Usuário</i></th>
                                <th width="100px" ><i>Updated</i></th> 
                                <th width="100px" ><i>Cód Cadastro</i></th>  
                                <th width="100px" ><i>Cadastro</i></th>    
                                <th width="100px" ><i>Ação</i></th>                          
                            </tr>
                        </thead>
                        
                        <tbody>
                            {listaLogs}
                        </tbody>
                        
                    </table>

                </div>
                {/* :

                null
            } */}


        </Dialog>
    );
}
import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaSecretaria } from '../../components/TabelaSecretaria';

export default function SecretariaPage() {

    return (
        <>           
            <MenuMain >
                <TabelaSecretaria />
            </MenuMain>
            <AppFooter />    
        </>
    );
}

import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'

import '../layout/sass/_responsiveTable.scss'
import { confirmDialog } from 'primereact/confirmdialog';
import api from '../api/api';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Checkbox } from 'primereact/checkbox';
import { FormUpload } from './FormUpload';
import { DialogLiquidar } from './DialogLiquidar';

export const TabelaLiquidacao = () => {
    const history = useHistory();
    const toast = useRef(null);
    const { user } = useContext(AuthContext);
    const [faturas, setFaturas] = useState([]);
    const [liquidar, setLiquidar] = useState([]);
    const [simular, setSimular] = useState([]);
    const [checkedFaturas, setCheckedFaturas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const [sortable, setSortable] = useState('ASC');
    const [first, setFirst] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortableCampo, setSortableCampo] = useState('id');
    const [showListaLiquidar, setShowListaLiquidar] = useState(false);
    const [visible, setVisible] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {}
    });

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Liquidação</h2>
            </div>

            <div className="p-my-0 p-grid p-dir-rev p-col">

                <DialogLiquidar
                    visible={showListaLiquidar}
                    setVisible={setShowListaLiquidar}
                    liquidar={liquidar}
                    simular={simular}
                    setLazyParams={setLazyParams}
                />

                <div className="p-m-2">
                    <Button
                        label="Liquidar"
                        className="p-mb-2 p-button-primary"
                        icon="pi pi-plus"
                        iconPos="left"
                        type="button"

                        onClick={() => handleLiquidar()}

                    />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Simular"
                        className="p-mb-2 p-button-primary"
                        icon="pi pi-plus"
                        iconPos="left"
                        type="button"

                        onClick={() => handleSimular()}

                    />
                </div>
            </div>

        </div>

    );

    const getFaturas = useCallback(async (event) => {
        setIsLoading(true)
        setFaturas([])

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }

        try {
            if (lazyParams.filters === undefined) {

                if (event === undefined) {
                    const response = await api.get(`faturas?sort=${sortableCampo},${sortable}`);

                    response.data.content.forEach(faturas => {
                        if (!faturas.dataLiquidacao)
                        {
                            setFaturas(fatura => [...fatura, faturas])
                        }
                    })
                } else {
                    const response = await api.get(`faturas?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(faturas => {
                        if (!faturas.dataLiquidacao)
                        {
                            setFaturas(fatura => [...fatura, faturas])
                        }
                    })

                }

                const respElements = await api.get(`faturas?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } else {
                const pesquisa = {
                    "instalacaoNumeroInstalacao": lazyParams.filters['instalacaoNumeroInstalacao'] !== undefined ? lazyParams.filters['instalacaoNumeroInstalacao'].value : null,
                    "numero": lazyParams.filters['numero'] !== undefined ? lazyParams.filters['numero'].value : null,
                    "fornecedorNome": lazyParams.filters['fornecedorNome'] !== undefined ? lazyParams.filters['fornecedorNome'].value : "",
                }

                if (event === undefined) {
                    const respElements = await api.post(`faturas/searchFaturas/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(faturas => {
                        if (!faturas.dataLiquidacao)
                        {
                            setFaturas(fatura => [...fatura, faturas])
                        }
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`faturas/searchFaturas/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(faturas => {
                        if (!faturas.dataLiquidacao)
                        {
                            setFaturas(fatura => [...fatura, faturas])
                        }
                    })
                    setTotalRecords(respElements.data.totalElements);
                }

            }

        }
        catch (error) {
            if (lazyParams !== undefined) {
                if (event === undefined) {
                    const response = await api.get(`faturas?sort=id,${sortable}`);
                    response.data.content.forEach(faturas => {
                        if (!faturas.dataLiquidacao)
                        {
                            setFaturas(fatura => [...fatura, faturas])
                        }
                    })
                } else {
                    const response = await api.get(`faturas?page=${event.page}&sort=id,${sortable}`);
                    response.data.content.forEach(faturas => {
                        if (!faturas.dataLiquidacao)
                        {
                            setFaturas(fatura => [...fatura, faturas])
                        }
                    })

                }

                const respElements = await api.get(`faturas?sort=id,${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter faturas', detail: error.toString() });
            }
        }

        setIsLoading(false)
    }, [lazyParams, sortable, sortableCampo]);

    useEffect(() => {
        const fetchdotacaoOrcamentaria = async () => {
            try {
                await getFaturas();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchdotacaoOrcamentaria();

    }, [getFaturas])

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const fornecedorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Fornecedor</span>
                    <span className="table-data">{rowData.fornecedor.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Emissão</span>
                    <span className="table-data">{new Date(rowData.dataEmissao).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataLiquidacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Liquidação</span>
                    <span className="table-data">{rowData.dataLiquidacao != undefined ? new Date(rowData.dataLiquidacao).toLocaleDateString() : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataVencimentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Liquidação</span>
                    <span className="table-data">{new Date(rowData.dataVencimento).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const numeroBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Número</span>
                    <span className="table-data">{rowData.numero}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorTotalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Total dos Serviços</span>
                    <span className="table-data">{rowData.valorTotal.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorRetencaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Retenção</span>
                    <span className="table-data">{rowData.valorRetencao.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}</span>
                </p>
            </React.Fragment>
        );
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)} /> */}



                <Checkbox inputId={rowData.id} value={rowData.id} onChange={onFaturasChange} checked={checkedFaturas.includes(rowData.id)}></Checkbox>

            </div>
        );
    }

    const onFaturasChange = (e) => {
        let selectedFaturas = [...checkedFaturas];
        if (e.checked)
            selectedFaturas.push(e.value);
        else
            selectedFaturas.splice(selectedFaturas.indexOf(e.value), 1);


        // console.log(selectedFaturas);
        setCheckedFaturas(selectedFaturas);

    }



    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const handleEditClick = (data) => {

        history.push(`/faturas/edit/${data.id}`);

    }

    const handleLiquidar = async () => {

        const data = {
            'userEmail': user != null ? user.email : "",
            'faturas': checkedFaturas,
            'simular': false
        }

        try {

            // console.log(data);

            const response = await api.post(`faturas/liquidar`, data);
            // console.log(response.data);
            setSimular(false);
            setLiquidar([response.data])
            setShowListaLiquidar(true);

        }
        catch (error) {
            toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao liquidar', detail: error.toString() });
        }


    }

    const handleSimular = async () => {

        const data = {
            'userEmail': user != null ? user.email : "",
            'faturas': checkedFaturas,
            'simular': true
        }

        try {

            // console.log(data);

            const response = await api.post(`faturas/liquidar`, data);
            // console.log(response.data);
            setSimular(true);
            setLiquidar([response.data])
            setShowListaLiquidar(true);

        }
        catch (error) {
            toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao liquidar', detail: error.toString() });
        }


    }

    const handleDeleteClick = (data) => {

        data['userEmail'] = user != null ? user.email : ""

        confirmDialog({
            message: `Você tem certeza que quer remover a fatura?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    const response = await api.delete(`faturas/${data.id}`);

                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `a fatura foi removida com sucesso` });
                    getFaturas()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a fatura', detail: error.toString() });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    return (
        <>
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <FormUpload visible={visible} setVisible={setVisible} setLazyParams={setLazyParams} Titulo="Selecione as Faturas" uploadTipo="1" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">
                        <DataTable
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getFaturas}
                            header={header}
                            value={faturas}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma fatura encontrada"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} faturas`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            // rowsPerPageOptions={[50, 100]}
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={lazyParams.filters}
                        >

                            <Column
                                field="id"
                                header="Código"
                                sortable
                                body={idBodyTemplate}
                                lazyParams

                            />

                            <Column
                                field="fornecedorNome"
                                header="Fornecedor"
                                sortable
                                body={fornecedorBodyTemplate}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por Fornecedor"
                            />

                            <Column
                                field="dataEmissao"
                                header="Data de Emissão"
                                sortable
                                body={dataEmissaoBodyTemplate}
                                lazyParams
                            />

                            <Column
                                field="dataLiquidacao"
                                header="Data de Liquidação"
                                sortable
                                body={dataLiquidacaoBodyTemplate}
                                lazyParams

                            />

                            <Column
                                field="dataVencimento"
                                header="Data de Vencimento"
                                sortable
                                body={dataVencimentoBodyTemplate}
                                lazyParams

                            />

                            <Column
                                field="numero"
                                header="Número"
                                sortable
                                body={numeroBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por número"
                            />

                            <Column
                                field="valorTotal"
                                header="Valor Total dos Serviços"
                                sortable
                                body={valorTotalBodyTemplate}

                            />

                            <Column
                                field="valorRetencao"
                                header="Valor Retenção"
                                sortable
                                body={valorRetencaoBodyTemplate}

                            />

                            <Column
                                header="Ações"
                                body={actionBodyTemplate}

                            />

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}

import React from 'react';
import MenuMain from '../../MenuMain';
import { FormFaturas } from '../../components/FormFaturas';
import { AppFooter } from '../../AppFooter';


export default function CadastrarFaturasPage() {

  const defaultValues = {
    'fornecedorId': '',
    'dataEmissao': '',
    'dataLiquidacao': '',
    'dataVencimento': '',
    'numero': '',
    'valorTotal': 0,
    'valorRetencao': 0,
    'faturaInstalacoes': '', 
  }

  return (
    <div>
      <MenuMain>
         <FormFaturas
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}


import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';

export const FormItens = ({ isEditing, isLoading, defaultValues }) => {
    
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)


    const schema = Yup.object().shape({
        codigo: Yup
            .number()
            .required("O código é obrigatório"),
        descricaoEmpenho: Yup
            .string()
            .required("A descrição empenho é obrigatório"),
        descricaoFatura: Yup
            .string()
            .required("A descrição fatura é obrigatório"),
        unidade: Yup
            .string()
            .required("A unidade é obrigatório")
        

    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)                        

            try {
                if (!isEditing) {

                    await api.post("itens", data)
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.descricaoEmpenho} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/itens')

                    }, 2000)

                }
                else {

                    await api.put("itens", data)
                    
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.descricaoEmpenho} atualizado(a) com sucesso` });
                    setTimeout(() => {
                        
                        history.push('/itens')

                    }, 2000)
                }
            }
            catch (error) {
                
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar itens!', detail: error.toString()});
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar itens!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Itens</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="codigo"
                                className={classNames({ 'p-error': isFormFieldValid('codigo') })}
                            >
                                Código
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="codigo"
                                        name="codigo"
                                        type='number'
                                        className={classNames({ 'p-invalid': isFormFieldValid('codigo') })}
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.codigo && formik.touched.codigo &&
                                <ErrorValidationMessage message={formik.errors.codigo} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="descricaoEmpenho"
                                className={classNames({ 'p-error': isFormFieldValid('descricaoEmpenho') })}
                            >
                                Descrição Empenho
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="descricaoEmpenho"
                                        name="descricaoEmpenho"
                                        className={classNames({ 'p-invalid': isFormFieldValid('descricaoEmpenho') })}
                                        value={formik.values.descricaoEmpenho}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.descricaoEmpenho && formik.touched.descricaoEmpenho &&
                                <ErrorValidationMessage message={formik.errors.descricaoEmpenho} />
                            }                           
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="descricaoFatura"
                                className={classNames({ 'p-error': isFormFieldValid('descricaoFatura') })}
                            >
                                Descrição Fatura
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="descricaoFatura"
                                        name="descricaoFatura"
                                        className={classNames({ 'p-invalid': isFormFieldValid('descricaoFatura') })}
                                        value={formik.values.descricaoFatura}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.descricaoFatura && formik.touched.descricaoFatura &&
                                <ErrorValidationMessage message={formik.errors.descricaoFatura} />
                            }                           
                        </div>


                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="unidade"
                                className={classNames({ 'p-error': isFormFieldValid('unidade') })}
                            >
                                Unidade
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="unidade"
                                        name="unidade"
                                        className={classNames({ 'p-invalid': isFormFieldValid('unidade') })}
                                        value={formik.values.unidade}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.unidade && formik.touched.unidade &&
                                <ErrorValidationMessage message={formik.errors.unidade} />
                            }                           
                        </div>

                        

                    </div>
                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Salvar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />
                        }
                    </div>
                </form>
            </div>
        </div>

    );
}

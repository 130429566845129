import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaEmpenhoItens = ({ isEditing, atualizarEmpenhoItens}) => {
    const toast = useRef(null);
    const [empenhoItens, setEmpenhoItens] = useState([]);
    const [empenhosItens, setEmpenhosItens] = useState([]);
    const [itens, setItens] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const { id } = useParams();
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {}
    });

    useEffect(() => {
        const fetchEmpenhoItens = async () => {
            try {
                await getEmpenhoItens();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchEmpenhoItens();

    }, [])


    useEffect(() => {
        const fetchItens = async () => {
            try {
                await getItens();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchItens();

    }, [])


    const getEmpenhoItens = useCallback(async (event) => {
        setIsLoading(true)
        setEmpenhoItens([])

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }

        try {
            if (isEditing) {

                if (event === undefined) {
                    const response = await api.get(`empenhoItens/empenhoId/${id}?sort=${sortableCampo},${sortable}`);
                    response.data.forEach(empenhoItem => {
                        setEmpenhoItens(empenhoItens => [...empenhoItens, empenhoItem])
                    })
                } else {
                    const response = await api.get(`empenhoItens/empenhoId/${id}?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.forEach(empenhoItem => {
                        setEmpenhoItens(empenhoItens => [...empenhoItens, empenhoItem])
                    })

                }

                const respElements = await api.get(`empenhoItens/empenhoId/${id}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.length);
            }


        }
        catch (error) {
            if (lazyParams !== undefined) {
                if (isEditing) {

                    if (event === undefined) {
                        const response = await api.get(`empenhoItens/empenhoId/${id}?sort=id,${sortable}`);
                        response.data.forEach(empenhoItem => {
                            setEmpenhoItens(empenhoItens => [...empenhoItens, empenhoItem])
                        })
                    } else {
                        const response = await api.get(`empenhoItens/empenhoId/${id}?page=${event.page}&sort=id,${sortable}`);
                        response.data.forEach(empenhoItem => {
                            setEmpenhoItens(empenhoItens => [...empenhoItens, empenhoItem])
                        })

                    }

                    const respElements = await api.get(`empenhoItens/empenhoId/${id}?sort=id,${sortable}`);
                    setTotalRecords(respElements.data.length);
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter empenho Itens', detail: error.toString() });
            }
        }

        setIsLoading(false)
    }, [lazyParams, sortable, sortableCampo]);

    const getItens = async () => {
        setIsLoading(true)
        setItens([])

        try {

            if (isEditing) {
                const response = await api.get(`itens/itensAll`);

                response.data.map(async item => {
                    setItens(itens => [...itens, item])
                })

            }

        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter itens', detail: error.toString() });
        }

        setIsLoading(false)
    }




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-mr-2 p-button-danger"
                    loading={isDeleting}
                    onClick={() => handleDeleteClick(rowData)}
                />

            </div>
        );
    }


    const itensBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{itens.map(item => item.id == rowData.itensId ? item.codigo : '')}</span>
                </p>
            </React.Fragment>
        );
    }

    const descricaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Descrição</span>
                    <span className="table-data">{itens.map(item => item.id == rowData.itensId ? item.descricaoEmpenho : '')}</span>
                </p>
            </React.Fragment>
        );
    }

    const qtdeEmpBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Quantidade Empenho</span>
                    <span className="table-data">{rowData.qtdeEmp.toLocaleString('pt-BR')}</span>
                </p>
            </React.Fragment>
        );
    }

    const qtdeLiqBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Quantidade Liquidada</span>
                    <span className="table-data">{rowData.qtdeLiq}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorUnBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Unitário</span>
                    <span className="table-data">R$ {rowData.valorUn != undefined ? rowData.valorUn.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span>
                </p>
            </React.Fragment>
        );
    }



    const handleDeleteClick = (data) => {
        let tempEmpenho = [];
        confirmDialog({
            message: `Você tem certeza que quer remover ${data.descricaoEmpenho}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
                setIsDeleting(true);

                try {
                    setEmpenhoItens(empenhoItens.filter(item => item !== data));
                    empenhoItens.filter(item => item !== data).forEach(item => {
                        tempEmpenho.push(item.muda)
                    });
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Itens ${data.descricaoEmpenho} removida com sucesso` });

                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a muda', detail: error.response.data });
                }

                setIsDeleting(false);
            }

        });
    }

    useEffect(() => {

    }, [empenhoItens]);

    const onRowEditComplete1 = (e) => {
        let empenhoItens2 = [...empenhoItens];
        let { newData, index } = e;

        empenhoItens2[index] = newData;

        setEmpenhoItens(empenhoItens2);
    }

    const handleEdit = (data, campo, value) => {
        data[campo] = value;
        atualizarEmpenhoItens(empenhoItens)
    }

    const editarQtdeLiq = (options) => {

        return <InputNumber value={options.rowData.qtdeLiq} onValueChange={((e) => { handleEdit(options.rowData, 'qtdeLiq', e.value) })} mode="decimal" minFractionDigits={2} maxFractionDigits={2} currency="BRL" locale="pt-BR" />

    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={empenhoItens}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma empenho encontrada"
                        dataKey="id"
                        first={first}
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} empenhos`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20, 30]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort
                        onSort={onSort}
                        onFilter={onFilter}
                        filters={lazyParams.filters}
                    >

                        <Column style={{ width: '12%' }}
                            field="codigo"
                            header="Código"
                            sortable
                            body={itensBodyTemplate}

                        />


                        <Column
                            field="descricaoEmpenho"
                            header="Descrição"
                            sortable
                            body={descricaoBodyTemplate}

                        />



                        <Column
                            field="qtdeEmp"
                            header="Quantidade Empenho"
                            sortable
                            body={qtdeEmpBodyTemplate}
                        />


                        <Column
                            field="qtdeLiq"
                            header="Quantidade Liquidada"
                            sortable
                            body={qtdeLiqBodyTemplate}
                            editor={(options) => editarQtdeLiq(options)}
                            style={{ width: '20%' }}
                        />

                        <Column
                            rowEditor
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            bodyStyle={{ textAlign: 'center' }}>
                        </Column>


                        <Column
                            field="valorUn"
                            header="Valor Unitário"
                            sortable
                            body={valorUnBodyTemplate}
                        />



                        <Column
                            header="Ações"
                            body={actionBodyTemplate}

                        />

                    </DataTable>

                </div>
            </div>
        </div>

    );
}

import { useContext } from 'react';
import { UserMenuContext } from '../contexts/UserMenuContext';

export const useUserMenu = () => {
    const {
        isLoading,
        setIsLoading,
        getUserData,
        errorGettingData,
        setErrorGettingData,
        errorMessage,
        setErrorMessage,
    } = useContext(UserMenuContext)

    return {
        isLoading,
        setIsLoading,
        getUserData,
        errorGettingData,
        setErrorGettingData,
        errorMessage,
        setErrorMessage,
    }
}
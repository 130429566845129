import React, { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';
import { Toast } from 'primereact/toast';
import api from '../api/api';

export const DialogLiquidar = ({ visible, setVisible, liquidar, simular, setLazyParams }) => {
    const d = new Printd();
    const toast = useRef(null);
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
        
        .header-img {
            display: flex;
            justify-content: center;
        }

        .header {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .header h2 {
            margin-bottom: 0px;
        }

        .header h4 {
            margin-top: 7px;
        }
    `

    const listaLiquidar = liquidar.map((data) => {
        
        // console.log(data);

        const renderFaturasRows = () => {
            const faturas = data.faturas.map((item, index) => (
                <tr key={index}>
                    <td width="10%" align='center'><span>{item.numero}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorRetencao != undefined ? item.valorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorTotal != undefined ? item.valorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                </tr>
            ));
            const totalValorRetencao = data.faturas.reduce((total, item) => total + (item.valorRetencao || 0), 0);
            const totalValorTotal = data.faturas.reduce((total, item) => total + (item.valorTotal || 0), 0);
            faturas.push(
                <tr key="total">
                    <th width="100px" ><i>Total:</i></th>
                    <td align='center'><span><b>R$ {totalValorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                </tr>
            );
            return faturas;
        };

        const renderLiquidacoesRows = () => {
            const liquidacoes = data.liquidacoes.map((item, index) => (
                <tr key={index}>
                    <td width="10%" align='center'><span>{item.numeroEmpenho}</span></td>
                    <td width="10%" align='center'><span>R$ {item.saldoAtual != undefined ? item.saldoAtual.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorRetencao != undefined ? item.valorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorALiquidar != undefined ? item.valorALiquidar.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                </tr>
            ));
            const totalSaldoAtual = data.liquidacoes.reduce((total, item) => total + (item.saldoAtual || 0), 0);
            const totalValorRetencao = data.liquidacoes.reduce((total, item) => total + (item.valorRetencao || 0), 0);
            const totalValorALiquidar = data.liquidacoes.reduce((total, item) => total + (item.valorALiquidar || 0), 0);
            liquidacoes.push(
                <tr key="total">
                    <th width="100px" ><i>Total:</i></th>
                    <td align='center'><span><b>R$ {totalSaldoAtual.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorALiquidar.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                </tr>
            );
            return liquidacoes;
        };

        const renderLiquidacoesQtdeRows = () => {
            const liquidacoes = data.liquidacoesQtde.map((item, index) => (
                <tr key={index}>
                    <td width="10%" align='center'><span>{item.numeroEmpenho}</span></td>
                    <td width="10%" align='center'><span>{item.descricaoEmpenho}</span></td>                    
                    <td width="10%" align='center'><span>{item.codigo}</span></td>                    
                    <td width="10%" align='center'><span>{item.qtdeEmp != undefined ? item.qtdeEmp.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>{item.qtdeLiq != undefined ? item.qtdeLiq.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorEmp != undefined ? item.valorEmp.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorTotalEmp != undefined ? item.valorTotalEmp.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorTotalFat != undefined ? item.valorTotalFat.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                </tr>
            ));            
            const totalValorEmpenhado = data.liquidacoesQtde.reduce((total, item) => total + (item.valorTotalEmp || 0), 0);
            const totalValorFaturado = data.liquidacoesQtde.reduce((total, item) => total + (item.valorTotalFat || 0), 0);
            liquidacoes.push(
                <tr key="total">                    
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <th width="100px" ><i>Total:</i></th>                    
                    <td align='center'><span><b>R$ {totalValorEmpenhado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorFaturado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                </tr>
            );
            return liquidacoes;
        };


        return (
            <div>
                <h2>Faturas:</h2>
                <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th width="100px" ><i>N° Fatura</i></th>
                            <th width="100px" ><i>Valor Retenção</i></th>
                            <th width="100px" ><i>Valor Total</i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderFaturasRows()}
                    </tbody>
                </table>

                <h2>Liquidações por Valor:</h2>
                <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th width="100px" ><i>N° Empenho</i></th>
                            <th width="100px" ><i>Saldo Atual</i></th>
                            <th width="100px" ><i>Valor Retenção</i></th>
                            <th width="100px" ><i>Valor a Liquidar</i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderLiquidacoesRows()}
                    </tbody>
                </table>

                <h2>Liquidações por Qtde:</h2>
                <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th width="100px" ><i>N° Empenho</i></th>
                            <th width="100px" ><i>Descrição</i></th>
                            <th width="100px" ><i>Código</i></th>
                            <th width="100px" ><i>Qtde Emp</i></th>
                            <th width="100px" ><i>Qtde a Liq.</i></th>
                            <th width="100px" ><i>Valor Un</i></th>
                            <th width="100px" ><i>Valor Total Emp.</i></th>
                            <th width="100px" ><i>Valor Total Fat.</i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderLiquidacoesQtdeRows()}
                    </tbody>
                </table>

            </div>
        );

    })

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const handleLiquidar = () => {
        // console.log(liquidar)
        try {
            liquidar[0].faturas.forEach(async fatura => {
                let faturaCompleta = await api.get(`faturas/${fatura.id}`);
                faturaCompleta.data.dataLiquidacao = new Date().toISOString();
                await api.put(`faturas`, faturaCompleta.data);
    
            });
            liquidar[0].liquidacoes.forEach(async liquidacao => {
                let empenhoCompleto = await api.get(`empenho/${liquidacao.id}`);
                empenhoCompleto.data.valorLiquidado = liquidacao.valorALiquidar;
                await api.put(`empenho`, empenhoCompleto.data);
            });
            setVisible(false);
            toast.current.show({ life: 3000, severity: 'success', summary: 'Fatura(s) liquidadas com sucesso!' });
            setTimeout(() => {
    
                setLazyParams({
                    first: 0,
                    rows: 20,
                    sortField: null,
                    sortOrder: null,
                    filters: {}
                })
            }, 3000);
        } catch (error) {
            toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao liquidar', detail: error.toString() });
        }
        
    }

    const header = (
        <div>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
        </div>
    );

    return (
        <>
            <Toast ref={toast} position="bottom-right" />

            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                header={header}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '60vw', heigth: '5vw' }}
            >
                {/* {log.length > 0 ? */}
                <div id="print-content">
                    {listaLiquidar}

                </div>
                {/* :

                null
            } */}
                {simular === false ?
                    <div style={{ textAlign: 'right', paddingTop: '10px' }}>
                        <Button label="Liquidar" className="p-button-danger" icon="pi pi-check-square" onClick={handleLiquidar} />
                    </div>
                    :
                    <></>
                }

            </Dialog>
        </>
    );
}
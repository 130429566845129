import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaInstalacaoEmpenho = ({ isEditing, instalacaoEmpenho, setInstalacaoEmpenho }) => {
    const toast = useRef(null);
    const [instalaceosEmpenhos, setInstalacoesEmpenhos] = useState([]);
    const [empenhos, setEmpenhos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchInstalacaoEmpenho = async () => {
            try {
                await getInstalacaoEmpenho();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchInstalacaoEmpenho();

    }, [])

    useEffect(() => {
        const fetchEmpenhos = async () => {
            try {
                await getEmpenhos();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchEmpenhos();

    }, [])

    const getInstalacaoEmpenho = async () => {
        setIsLoading(true)
        setInstalacoesEmpenhos([])

        try {

            if (isEditing) {
                const response = await api.get(`instalacaoEmpenho/instalacao/${id}`);

                response.data.map(async empenho => {
                    setInstalacoesEmpenhos(instalacaoEmpenho => [...instalacaoEmpenho, empenho])
                })
            }

        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instalacões', detail: error.toString() });
        }

        setIsLoading(false)
    }

    const getEmpenhos = async () => {
        setIsLoading(true)
        setEmpenhos([])

        try {

            if (isEditing) {
                const response = await api.get(`empenho/empenhoAll`);                

                response.data.map(async empenho=> {
                    setEmpenhos(empenhos => [...empenhos, empenho])
                })
               
            }

        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter empenhos', detail: error.toString() });
        }

        setIsLoading(false)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-mr-2 p-button-danger"
                    loading={isDeleting}
                    onClick={() => handleDeleteClick(rowData)}
                />

            </div>
        );
    }

    const pesoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Peso</span>
                    <span className="table-data">{rowData.peso}</span>
                </p>
            </React.Fragment>
        );
    }

    const empenhoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">empenho</span>
                    <span className="table-data">{empenhos.map(empenho => empenho.id == rowData.empenhoId ? empenho.numeroEmpenho : '')}</span>
                </p>
            </React.Fragment>
        );
    }

    const handleDeleteClick = (data) => {
        let tempFaturas = [];
        confirmDialog({
            message: `Você tem certeza que quer remover ${data.descricao}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
                setIsDeleting(true);

                try {
                    setInstalacaoEmpenho(instalacaoEmpenho.filter(item => item !== data));
                    instalacaoEmpenho.filter(item => item !== data).forEach(item => {
                        tempFaturas.push(item.muda)
                    });
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Item ${data.descricao} removida com sucesso` });

                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a muda', detail: error.response.data });
                }

                setIsDeleting(false);
            }

        });
    }

    useEffect(() => {

    }, [instalacaoEmpenho]);

    const onRowEditComplete1 = (e) => {
        let instalacaoEmpenho2 = [...instalacaoEmpenho];
        let { newData, index } = e;

        instalacaoEmpenho2[index] = newData;

        setInstalacaoEmpenho(instalacaoEmpenho2);
    }

    const handleEdit = (data, campo, value) => {
        data[campo] = value;

    }

    const editarPeso = (options) => {

        return <InputNumber value={options.rowData.peso} onValueChange={((e) => { handleEdit(options.rowData, 'peso', e.value) })} mode="decimal" minFractionDigits={2} maxFractionDigits={2} currency="BRL" locale="pt-BR" />

    }

  

    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={instalacaoEmpenho}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma instalação encontrada"
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} instalacões"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20, 30]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort

                    >
                       

                        <Column
                            field="empenho"
                            header="Código do Empenho"
                            sortable
                            body={empenhoBodyTemplate}

                        />

                        <Column
                            field="peso"
                            header="Peso"
                            sortable
                            body={pesoBodyTemplate}
                            editor={(options) => editarPeso(options)}
                            style={{ width: '30%' }}

                        />

                        <Column
                            rowEditor
                            headerStyle={{ width: '20%', minWidth: '8rem' }}
                            bodyStyle={{ textAlign: 'center' }}>
                        </Column>

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}

                        />

                    </DataTable>

                </div>
            </div>
        </div>

    );
}

import React, { useEffect, useState } from 'react';

import { FormInstalacao } from '../../components/FormInstalacao';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarInstalacaoPage(props) {

    const [instalacao, setInstalacao] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingInstalacao, setErrorGettingInstalacao] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idInstalacao] = useState(props.match.params.id)

    const getInstalacao = async (id) => {
        setIsLoading(true);

        try {
            const response = await api.get(`instalacao/${id}`)
            
            setInstalacao({
                'id': response.data.id,
                'empenhoId': response.data.empenhoId,
                'responsavel': response.data.responsavel,    
                'endereco': response.data.endereco,      
                'numeroInstalacao': response.data.numeroInstalacao, 
                'dataVigencia': response.data.dataVigencia,
                'medidor': response.data.medidor,
                'descricao': response.data.descricao,
                'observacao': response.data.observacao,
                'itens': response.data.itens, 
            })

            setErrorGettingInstalacao(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingInstalacao(true)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getInstalacao(idInstalacao)
    }, [])

    return (
        <>
            <MenuMain >
                {
                    errorGettingInstalacao ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getInstalacao(idInstalacao)}
                            isLoading={isLoading}
                        />
                        :
                        <FormInstalacao
                            defaultValues={instalacao}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}

import React from 'react';

import UserMenuProvider from '../../contexts/UserMenuContext';

import { UserMenu } from '../../components/UserMenu';


export default function HomeUserPage() {

    return (
        <UserMenuProvider>
            <UserMenu />
        </UserMenuProvider>
    );
}


import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaFornecedor } from '../../components/TabelaFornecedor';

export default function FornecedorPage() {

    return (
        <>           
            <MenuMain >
                <TabelaFornecedor />
            </MenuMain>
            <AppFooter />    
        </>
    );
}
